<template>
  <b-row>
    <b-col></b-col>

    <b-col>  
    <div class="theLoginForm">
        <div class="theLoginForm__heading"></div>

        <b-form v-if="!isAccountCreated" class="theLoginForm__form" @submit.prevent="creteAccount">
        
          <h4 class="theLoginForm__resetPsw--title">{{ $t("signup.title") }}</h4>
          <br>
          <b-form-input class="theLoginForm__form__email"  type="email" name="email" :placeholder="$t('signup.email')" required v-model="email"></b-form-input>
          <b-form-input class="theLoginForm__form__email" name="username" :placeholder="$t('signup.name')" required  v-model="username"></b-form-input>

          <div>
            <div class="theLoginForm__form__passwordSignup">
                <input class="theLoginForm__form__password--input" autocomplete="current-password"  id="password" name="password" :placeholder="$t('password.label')" required :type="fieldType" v-model="password"/>
                <button class="theLoginForm__form__showPasswordButton" @click="showPassword" type="button"><i :class="`theLoginForm__form${fieldType=='password' ? '__showPasswordIcon' : '__hidePasswordIcon' }`"></i></button>
            </div>
            <b-form-invalid-feedback :state="validation">{{ $t("password.reset.validation") }}</b-form-invalid-feedback>
            <b-form-valid-feedback :state="validation"></b-form-valid-feedback>
          </div>

          <button class="primary" type="submit">{{ $t("signup.create")}}</button>
          <p v-if="resetError=='400'">{{ $t("password.reset.tooeasy")}}</p>
          <p v-if="resetError=='not match'">{{ $t("password.reset.notvalid")}}</p>
        </b-form> 

      <!-- Account created -->
        <div v-if="isAccountCreated">
          
          <div class="theLoginForm__resetPsw--resetPswDone">
            <h4 class="theLoginForm__resetPsw--title">{{ $t("signup.done") }}</h4>
            <div>
              <p>{{ $t("signup.message") }}</p>
            </div>
            <button class="primary" @click="backToLogin">{{ $t("signup.continue") }}</button>

          </div>
        </div>
      <!---->

    </div>
    </b-col>
    
    <b-col></b-col>
    </b-row>
</template>

<script>

import axios from "axios";
export default {
  data() {
    return {
      email: '',
      password: '',
      username: '',
      confirmPassword: '',
      resetError: '',
      isAccountCreated: false,
      fieldType: "password"
    };
  },
  computed: {
    validation() {
      return this.password.length > 7 
    }
  },

  methods:{
    async creteAccount() {
      let token = []
      token = this.$route.params.jwt.split("&");
      let _token = token[0]
      let checkPsw = this.checkPassword(this.password)
      if (this.password.length < 8) {
        return;
      }
      try {
        
        if(checkPsw){
           // Effettua la chiamata API per reimpostare la password
          await axios.post('/user/setup-account', {   
                name: this.username, password: this.password, token: _token }).then( res=>{
            if(res.status === 200){
              this.isAccountCreated = true
            }
          });
        }
        else{
          this.resetError = '400'
        return;
        }
       
      } catch (error) {
        if(error.response.status === 400){
            this.resetError = '400';
        }
      }
    },

    showPassword(){
      if (this.fieldType == "password") {
        this.fieldType = "text";
      } else this.fieldType = "password";
    },

    backToLogin(){
      this.$router.push("/login");
    },
    checkPassword(str){
      var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(str);
    }
  }
  

}
</script>